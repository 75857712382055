import { handleFetchSuccess } from '../../../utils/handleFetchSuccess';
import * as types from './types';

const initialState = {
    list: [],
    listFetching: true,
    listPagination: {
        current_page: 1,
        last_page: 1,
        total: 1,
    },
    single: null,
    singleFetching: true,
};

export const messagesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.FETCH_MESSAGES_LIST:
            return { ...state, listFetching: true };

        case types.FETCH_MESSAGES_LIST_SUCCESS:
            return handleFetchSuccess(state, payload);

        case types.FETCH_MESSAGES_LIST_FAILURE:
            return { ...state, listFetching: false };

        case types.FETCH_SINGLE_MESSAGE:
            return { ...state, single: null, singleFetching: true };

        case types.FETCH_SINGLE_MESSAGE_SUCCESS:
            return { ...state, singleFetching: false, single: payload };

        case types.FETCH_SINGLE_MESSAGE_FAILURE:
            return { ...state, singleFetching: false };

        case types.CLEAR_SINGLE_MESSAGE:
            return {...state, single: null}
            
        default:
            return state;
    }
};
