import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import history from '../../history'
import { setToken } from '../../utils'

import {
  Form,
  Input,
  Button
} from 'antd'

import './login.scss'

const Login = props => {
  const { user, userLogin } = props

  useEffect(() => {
    if (user.status === 'success') {
      setToken(user.data.token)
      history.push('/')
    }
  }, [user.status])

  return (
    <div className='login'>
      <Form className='login__form'
        name='login'
        onFinish={userLogin}
      >
        <Form.Item
          label='Username or E-mail'
          name='email'
          className='login__input'
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input placeholder='Login or email' />
        </Form.Item>

        <Form.Item
          label='Password'
          name='password'
          className='login__input'
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input type='password' placeholder='Password' />
        </Form.Item>

        <Form.Item>
          <Button
            loading={user.status === 'fetching'}
            type='primary'
            htmlType='sumbit'
            block
            className='btn btn-solid'
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

Login.propTypes = {
  user: PropTypes.object,
  userLogin: PropTypes.func
}

export default Login
