import Admin from './Admin'
import { connect } from 'react-redux'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  admin: state.admin
})

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(actions.admin.getUsers()),
  getPositions: () => dispatch(actions.admin.getPositions()),
  getJobs: () => dispatch(actions.admin.getJobs()),
  getCustomer: () => dispatch(actions.admin.getCustomer()),
  getServices: () => dispatch(actions.admin.getServices())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin)
