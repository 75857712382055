import { Button, Col, message, Popover, Row } from 'antd';
import React from 'react';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { deleteMessage } from '../../../../services/api/messages';

export const NavBar = ({isAdmin, id}) => {
    const [confirmVisible, setConfirmVisible] = useState(false);
    const history = useHistory();
    const backRoute = isAdmin ? '/maillist' : '/';

    const handleConfirmDelete = () => {
        setConfirmVisible(false)
        deleteMessage(id)
            .then(() => {
                message.success('Notification has been deleted successfully!')

                goBack()
            })
            .catch(() => message.error('Something went wrong during delete this notification'))
    }

    const goBack = () => {
        if(history.location.state?.prev?.pathname?.includes('/mailing/')) {
            history.push(backRoute)
        }
        else {
            history.goBack();
        }
    }
    
    const toggleVisible = () => setConfirmVisible(!confirmVisible)

    return (
        <Row justify="space-between" gutter="20">
            <Col>
                <Button type="primary" className="btn btn-solid btn-small btn-left" onClick={goBack} >
                    Back
                </Button>
            </Col>
            {isAdmin && (
                <Row gutter="10">
                <Col>
                    <Button type="primary" className="btn btn-solid btn-small btn-left" >
                        <Link to={{pathname: `/mailing/${id}`, state: {prev: history.location}}}>Edit</Link>
                    </Button>
                </Col>
                <Col>
                    <Popover 
                        title="Are you sure you want to delete this notification?" 
                        content={<><Button onClick={handleConfirmDelete} style={{marginRight: '1rem'}}>Confirm</Button><Button onClick={toggleVisible}>Cancel</Button></>} 
                        trigger="click"
                        visible={confirmVisible}
                    >
                        <Button 
                            type="primary" 
                            className="btn btn-solid btn-small btn-left" 
                            onClick={toggleVisible}
                        >
                            Delete
                        </Button>
                    </Popover>
                </Col>
                </Row>
            )}
        </Row>
    )
}