import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Redirect, Router, Switch } from 'react-router-dom';

import { connect } from 'react-redux';

import history from './history';

import { Templates } from './components';

import { Login, Admin, Users, Mailing, UserPage, MailList } from './views';

import Header from './components/Organism/Header/Header';
import Footer from './components/Organism/Footer/Footer';

import './styles/styleEdit.scss';
import { MailItemView } from './views/MailItemView/MailItemView';

const { PublicRoute, PrivateRoute } = Templates;

const App = ({ user: { infoMe } }) => {
    const [userAdmin, setUserAdmin] = useState(null);

    useEffect(() => {
        let admin = false;

        if (infoMe?.roles?.length) {
            for (let i = 0; i < infoMe.roles.length; i++) {
                if (infoMe.roles[i].name === 'admin') {
                    admin = true;
                }
            }
        }

        setUserAdmin(admin);
    }, [infoMe]);

    return (
        <Router history={history}>
            <Header />
            <Switch>
                <PublicRoute path="/login" component={Login} exact />
                {userAdmin ? (
                    <>
                        <PrivateRoute path="/" exact component={Admin} />
                        <PrivateRoute path="/users" exact component={Users} />
                        {/* <PrivateRoute path='/upload' exact component={UploadFile} />
            <PrivateRoute path='/library' exact component={Library} /> */}
                        <PrivateRoute path="/mailing" exact component={Mailing} />
                        <PrivateRoute path="/mailing/:id" exact component={Mailing} />
                        <PrivateRoute path="/maillist" exact component={MailList} />
                        <PrivateRoute path="/maillist/:id" exact component={MailItemView} />
                        <Redirect from="*" to="/" />
                    </>
                ) : (
                    <>
                        <PrivateRoute path="/" exact component={UserPage} />
                        <PrivateRoute path="/maillist/:id" exact component={MailItemView} />
                        <Redirect from="*" to="/" />
                    </>
                )}
            </Switch>
            <Footer />
        </Router>
    );
};

App.propTypes = {
    user: PropTypes.object,
};

const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(App);
