import http from './http';

export const getMessages = (params, isAdmin) => {
    const route = isAdmin ? 'messages' : 'userMessages';

    return http({
        url: `api/v1/${route}?f_params[orderBy][field]=created_at&f_params[orderBy][type]=DESC`,
        method: 'GET',
        // params,
    });
};

export const getMessage = (id, isAdmin) => {
    const route = isAdmin ? 'messages' : 'messageDetail';

    return http({
        url: `api/v1/${route}/${id}`,
        method: 'GET',
    });
};

export const searchMessages = (search, isAdmin) => {
    const route = isAdmin ? 'search' : 'userSearch';
    return http({
        url: `api/v1/${route}?f_params[orderBy][field]=created_at&f_params[orderBy][type]=DESC${
            search.page ? '&page=' + search.page : ''
        }`,
        method: 'POST',
        data: {
            search: typeof search === 'string' ? search : search.search,
        },
    });
};

export const deleteMessage = id =>
    http({
        url: `api/v1/messages/${id}`,
        method: 'DELETE',
    });

export const createMessageFiles = (id, files) =>
    http({
        url: `api/v1/messages/${id}/files`,
        method: 'POST',
        data: files,
    });

export const deleteMessageFiles = (id, files) =>
    http({
        url: `api/v1/messages/${id}/files`,
        method: 'DELETE',
        data: { filesId: files },
    });
