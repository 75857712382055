import React from 'react';
import { NavLink } from 'react-router-dom';

import './admin.scss';

const Admin = () => (
    <div className="l-admin">
        <div className="l-admin__block">
            <NavLink exact to="/mailing">
                <img
                    src="/images/upload-document.png"
                    alt="Icon for upload document"
                    className="l-admin__icon"
                />
                Documents
            </NavLink>
        </div>
        <div className="l-admin__block">
            <NavLink exact to="/maillist">
                <img
                    src="/images/mass-mailing.png"
                    alt="Icon for mass mailing"
                    className="l-admin__icon"
                />
                Notifications
            </NavLink>
        </div>
        <div className="l-admin__block">
            <NavLink exact to="/users">
                <img
                    src="/images/user-management.png"
                    alt="Icon for user management"
                    className="l-admin__icon"
                />
                User management
            </NavLink>
        </div>   
    </div>
);

export default Admin;
