import React, {useRef} from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';


export const FileUpload = ({files, setFiles, setRemovedFiles}) => {
    const inputRef = useRef();

    const handleRemoveFile = file => {
        const newState = files.filter(f => f.name !== file.name)

        setFiles(newState)
        setRemovedFiles(s => file.id ? [...s, file.id] : s)
    }

    const handleInputChange = e => {
        setFiles(s => ([...s, ...Object.values(e.target.files)]))

        if(inputRef.current) {
            inputRef.current.values = undefined;
        }
    }

    const columns = [
        {
          title: "Name file",
          dataIndex: 'name',
          key: "name",
        },
        {
          key: "button",
          align: "right",
          render: (data) => (
            <Button danger onClick={() => handleRemoveFile(data)}>
              Delete
            </Button>
          ),
        },
    ];
      
    return (
        <div>
            <label htmlFor="selectFiles" className="ant-btn"><UploadOutlined /><span>Upload files</span></label>
            <input ref={inputRef} id="selectFiles" type="file" multiple hidden onChange={handleInputChange}/>
            {files.length > 0 && <Table dataSource={files} rowKey="name" columns={columns} pagination={false} />}
        </div>
    )
}