import MailList from './MailList'

import { connect } from 'react-redux'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getListRole: () => dispatch(actions.user.getListRole()),
  createMessage: (data) => dispatch(actions.user.createMessage(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailList)



//getListDocType