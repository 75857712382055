import http from './http';

function userLogin(data) {
    return http({
        url: 'api/v1/login',
        method: 'POST',
        data,
    });
}

function userLogout() {
    return http({
        url: 'api/v1/logout',
        method: 'GET',
    });
}

// USER

function getUsers(id) {
    return http({
        url: `api/v1/users${id ? '?page=' + id : ''}`,
        method: 'GET',
    });
}

function getUsersBySearch(query, pageNumber) {
    return http({
        url: `api/v1/searchByUser?search=${query}${pageNumber ? '&page=' + pageNumber : ''}`,
        method: 'GET',
    });
}

function updateUser(id, data) {
    return http({
        url: `api/v1/users/${id}`,
        method: 'PATCH',
        data,
    });
}

function createUser(data) {
    return http({
        url: 'api/v1/users',
        method: 'POST',
        data,
    });
}

function deleteUser(id) {
    return http({
        url: `api/v1/users/${id}`,
        method: 'DELETE',
    });
}

// ROLE

function getListRole() {
    return http({
        url: 'api/v1/roles',
        method: 'GET',
    });
}

function createRole(data) {
    return http({
        url: 'api/v1/roles',
        method: 'POST',
        data,
    });
}

function updateRole(id, data) {
    return http({
        url: `api/v1/roles/${id}`,
        method: 'PATCH',
        data,
    });
}

function deleteRole(id) {
    return http({
        url: `api/v1/roles/${id}`,
        method: 'DELETE',
    });
}

// DOCS

function getListDocs(idPage) {
    return http({
        url: `api/v1/docs?page=${idPage}&f_params[orderBy][field]=created_at&f_params[orderBy][type]=DESC`,
        method: 'GET',
    });
}

function createDocs(data) {
    return http({
        url: 'api/v1/docs',
        method: 'POST',
        data,
    });
}

function updateDocs(id, data) {
    return http({
        url: `api/v1/docs/${id}`,
        method: 'PATCH',
        data,
    });
}

function deleteDocs(id) {
    return http({
        url: `api/v1/docs/${id}`,
        method: 'DELETE',
    });
}

// DOC_TYPE

function getListDocType() {
    return http({
        url: 'api/v1/doc_types',
        method: 'GET',
    });
}

function createDocType(data) {
    return http({
        url: 'api/v1/doc_types',
        method: 'POST',
        data,
    });
}

function updateDocType(id, data) {
    return http({
        url: `api/v1/doc_types/${id}`,
        method: 'PATCH',
        data,
    });
}

function deleteDocType(id) {
    return http({
        url: `api/v1/doc_types/${id}`,
        method: 'DELETE',
    });
}

// ARCHIVE

function getListArchive(idPage) {
    return http({
        url: `api/v1/archive?page=${idPage}&f_params[orderBy][field]=created_at&f_params[orderBy][type]=DESC`,
        method: 'GET',
    });
}

// TOPIC

function getListTopic() {
    return http({
        url: 'api/v1/topics',
        method: 'GET',
    });
}

function createTopic(data) {
    return http({
        url: 'api/v1/topics',
        method: 'POST',
        data,
    });
}

function updateTopic(id, data) {
    return http({
        url: `api/v1/topics/${id}`,
        method: 'PATCH',
        data,
    });
}

function deleteTopic(id) {
    return http({
        url: `api/v1/topics/${id}`,
        method: 'DELETE',
    });
}

// createMessage

function createMessage(data) {
    return http({
        url: 'api/v1/messages',
        method: 'POST',
        data,
    });
}
function updateMessage(data, id) {
    return http({
        url: `api/v1/messages/${id}`,
        method: 'POST',
        data,
    });
}

export const fetchMessages = () =>
    http({
        url: 'api/v1/messages',
        method: 'GET',
    });

// ME

function getMe() {
    return http({
        url: 'api/v1/me',
        method: 'GET',
    });
}

function getListDocsMe(page) {
    return http({
        url: `api/v1/mydocs?page=${page}&f_params[orderBy][field]=created_at&f_params[orderBy][type]=DESC`,
        method: 'GET',
    });
}

function getListDocsFavoritesMe(page) {
    return http({
        url: `api/v1/favorites?page=${page}&f_params[orderBy][field]=created_at&f_params[orderBy][type]=DESC`,
        method: 'GET',
    });
}

function addToFavorites(data) {
    return http({
        url: 'api/v1/addToFavorites',
        method: 'POST',
        data,
    });
}

function deleteToFavorites(data) {
    return http({
        url: 'api/v1/deleteFromFavorites',
        method: 'POST',
        data,
    });
}

export {
    userLogin,
    userLogout,
    getMe,
    getListDocsMe,
    getListDocsFavoritesMe,
    addToFavorites,
    deleteToFavorites,
    getUsers,
    getUsersBySearch,
    updateUser,
    createUser,
    deleteUser,
    getListRole,
    createRole,
    updateRole,
    deleteRole,
    getListDocs,
    createDocs,
    updateDocs,
    deleteDocs,
    getListArchive,
    getListDocType,
    createDocType,
    updateDocType,
    deleteDocType,
    getListTopic,
    createTopic,
    updateTopic,
    deleteTopic,
    createMessage,
    updateMessage,
};
