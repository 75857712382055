import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from './Item.module.scss';

export const Item = ({ message }) => {
    const [day, month, year] = moment(message.created_at).format('DD.MMM.YYYY').split('.');
    const text = message.body.replace(/style=".*?"/gmi, '')

    return (
        <Link className={styles.wrap} to={`/maillist/${message.id}`}>
            <div className={styles.date}>
                <div className={styles.dateD}>{day}</div>
                <div className={styles.dateM}>{month}</div>
                <div className={styles.dateY}>{year}</div>
            </div>
            <div className={styles.info}>
                <div className={styles.title}>
                    <div >{message.subject}</div>
                </div>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: text.split('<p>').filter(i => i.slice(0, i.length).indexOf("span") !== -1)[0] }} />
            </div>
        </Link>
    );
};
