import React from 'react';
import moment from 'moment';
import styles from './MainInfo.module.scss';

export const MainInfo = ({ message }) => {
    const date = moment(message.created_at).format('DD/MM/YYYY');

    return (
        <>
            <div className={styles.title}>
                {message.subject}, {date}
            </div>
            <div className={styles.content} dangerouslySetInnerHTML={{__html: message.body}}/>
        </>
    );
};
