import Sidebar from './Sidebar'
import { connect } from 'react-redux'
import actions from '../../../store/actions'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  userLogout: () => dispatch(actions.user.userLogout())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)
