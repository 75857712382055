import React from 'react';
import { NavLink } from 'react-router-dom';
import { isLogin } from '../../../utils';
import { Redirect, Route } from 'react-router-dom';

import './Sidebar.scss';

const Sidebar = props => {
    return (
        <div
            id="sidebar"
            className={props.vis ? 'c-sidebar open slideInRight' : 'c-sidebar slideInLeft'}
        >
            <nav className="c-sidebar__menu">
                <a href="http://cius.org/about-us">About us</a>
                <a href="http://cius.org/our-vision">Our vision</a>
                <a href="http://cius.org/background-information">The EU Sugar Market</a>
                <a href="http://cius.org/contact-us">Contact us</a>
                <a href="http://cius.org/links">Links</a>
                <a href="http://cius.org/news">News</a>

                {isLogin() ? (
                    <>
                        <NavLink exact to="/">
                            Membership area
                        </NavLink>
                        <button className="btn" onClick={props.userLogout}>
                            {' '}
                            Logout
                        </button>
                    </>
                ) : (
                    <NavLink className="btn" exact to="/">
                        Login
                    </NavLink>
                )}
            </nav>
            <button className="btn-close" onClick={props.funk}>
                &#10006;
            </button>
        </div>
    );
};

export default Sidebar;
