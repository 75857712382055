import Mailing from './Mailing'

import { connect } from 'react-redux'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getListRole: () => dispatch(actions.user.getListRole()),
  createMessage: (data) => dispatch(actions.user.createMessage(data)),
  updateMessage: (data) => dispatch(actions.user.updateMessage(data)),
  getListDocType: () => dispatch(actions.user.getListDocType()),
  getListTopic: () => dispatch(actions.user.getListTopic())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Mailing)
