import * as t from '../types';
import api from '../../services';
import { clearToken } from '../../utils';
import { message } from 'antd';
import history from '../../history';

const userLogin = data => dispatch => {
    dispatch({ type: t.USER_LOGIN_REQUEST });

    return api.user
        .userLogin(data)
        .then(response => {
            const { data, error, status } = response;
            if (status === 200) {
                dispatch({
                    type: t.USER_LOGIN_SUCCESS,
                    payload: data,
                });

                return response;
            } else {
                throw new Error(error);
            }
        })
        .catch(err => {
            dispatch({
                type: t.USER_LOGIN_FAILURE,
                payload: err.message,
            });
            message.error('Wrong login or password!');
        });
};

const userLogout = () => dispatch => {
    clearToken();
    history.push('/login');
};

// Me

const getMe = () => dispatch => {
    return api.user.getMe().then(response => {
        const { data, error, status } = response;
        if (status === 200) {
            dispatch({
                type: t.GET_ME,
                payload: data,
            });

            return response;
        } else {
            throw new Error(error);
        }
    });
};

const getListDocsMe = page => dispatch => {
    return api.user.getListDocsMe(page).then(response => {
        const { data, error, status } = response;
        if (status === 200) {
            dispatch({
                type: t.GET_MY_DOCS,
                payload: data,
            });

            return response;
        } else {
            throw new Error(error);
        }
    });
};

const getListDocsFavoritesMe = page => dispatch => {
    return api.user.getListDocsFavoritesMe(page).then(response => {
        const { data, error, status } = response;
        if (status === 200) {
            dispatch({
                type: t.GET_MY_FAVORITES_DOCS,
                payload: data,
            });

            return response;
        } else {
            throw new Error(error);
        }
    });
};

const addToFavorites = data => dispatch => {
    return api.user
        .addToFavorites(data)
        .then(response => {
            const { error, status } = response;
            if (status === 200) {
                dispatch({
                    type: t.ADD_TO_FAVORITES,
                    payload: data,
                });

                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(() => message.success('add to Favorites!'));
};

const deleteToFavorites = data => dispatch => {
    return api.user
        .deleteToFavorites(data)
        .then(response => {
            const { error, status } = response;
            if (status === 200) {
                dispatch({
                    type: t.DELETE_TO_FAVORITES,
                    payload: data,
                });

                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(() => message.success('Delete to Favorites!'));
};

// USER

const getUsers = id => dispatch => {
    return api.user.getUsers(id).then(response => {
        const { data, error, status } = response;
        if (status === 200) {
            dispatch({
                type: t.GET_USERS,
                payload: data,
            });

            return response;
        } else {
            throw new Error(error);
        }
    });
};

const getUsersBySearch = (query, pageNumber) => dispatch => {
    return api.user.getUsersBySearch(query, pageNumber)
        .then(response => {
            const { data, error, status } = response;
            if (status === 200) {
                dispatch({
                    type: t.GET_USERS_BY_SEARCH,
                    payload: data,
                });

                return response;
            } else {
                throw new Error(error);
            }
    });
};

const updateUser = (id, data, page) => dispatch => {
    return api.user
        .updateUser(id, data)
        .then(response => {
            const { error, status } = response;
            if (status === 200) {
                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(item => {
            api.user
                .getUsers(page)
                .then(response => {
                    const { data, error, status } = response;
                    if (status === 200) {
                        dispatch({
                            type: t.GET_USERS,
                            payload: data,
                        });

                        return response;
                    } else {
                        throw new Error(error);
                    }
                })
                .finally(() => message.success('User has been updated successfully'));
        });
};

const createUsers = data => dispatch => {
    return api.user
        .createUser(data[0])
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(item => {
            api.user
                .getUsers(data[1])
                .then(response => {
                    const { data, error, status } = response;
                    if (status === 200) {
                        dispatch({
                            type: t.GET_USERS,
                            payload: data,
                        });

                        return response;
                    } else {
                        throw new Error(error);
                    }
                })
                .finally(() => message.success('User has been created successfully'));
        });
};

const deleteUser = id => dispatch => {
    return api.user
        .deleteUser(id)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                dispatch({
                    type: t.DELETE_USER,
                    payload: id,
                });

                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(() => message.success('User has been deleted successfully'));
};

// ROLE

const getListRole = () => dispatch => {
    return api.user.getListRole().then(response => {
        const { data, error, status } = response;
        if (status === 200) {
            dispatch({
                type: t.GET_ROLES,
                payload: data,
            });

            return response;
        } else {
            throw new Error(error);
        }
    });
};

const updateRole = (id, data) => dispatch => {
    return api.user
        .updateRole(id, data)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(item => {
            api.user
                .getListRole()
                .then(response => {
                    const { data, error, status } = response;
                    if (status === 200) {
                        dispatch({
                            type: t.GET_ROLES,
                            payload: data,
                        });

                        return response;
                    } else {
                        throw new Error(error);
                    }
                })
                .finally(() => message.success('Role has been updated successfully'));
        });
};

const createRole = data => dispatch => {
    return api.user
        .createRole(data)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(item => {
            api.user
                .getListRole()
                .then(response => {
                    const { data, error, status } = response;
                    if (status === 200) {
                        dispatch({
                            type: t.GET_ROLES,
                            payload: data,
                        });

                        return response;
                    } else {
                        throw new Error(error);
                    }
                })
                .finally(() => message.success('Role has been created successfully'));
        });
};

const deleteRole = id => dispatch => {
    return api.user
        .deleteRole(id)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                dispatch({
                    type: t.DELETE_ROLE,
                    payload: id,
                });

                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(() => message.success('Role has been deleted successfully'));
};

// DOCS

const getListDocs = idPage => dispatch => {
    return api.user.getListDocs(idPage).then(response => {
        const { data, error, status } = response;
        if (status === 200) {
            dispatch({
                type: t.GET_DOCS,
                payload: data,
            });

            return response;
        } else {
            throw new Error(error);
        }
    });
};

const updateDocs = (id, d) => dispatch => {
    return api.user.updateDocs(id, d).then(response => {
        const { data, error, status } = response;
        if (status === 201 || status === 200) {
            dispatch({
                type: t.UPDATE_DOCS,
                payload: data,
            });

            return response;
        } else {
            throw new Error(error);
        }
    });
};

const createDocs = data => dispatch => {
    return api.user
        .createDocs(data)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(item => {
            api.user.getListDocs().then(response => {
                const { data, error, status } = response;
                if (status === 200) {
                    dispatch({
                        type: t.GET_DOCS,
                        payload: data,
                    });

                    return response;
                } else {
                    throw new Error(error);
                }
            });
            api.user
                .getListArchive()
                .then(response => {
                    const { data, error, status } = response;
                    if (status === 200) {
                        dispatch({
                            type: t.GET_ARCHIVE,
                            payload: data,
                        });

                        return response;
                    } else {
                        throw new Error(error);
                    }
                })
                .finally(() => message.success('Create Docs!'));
        });
};

const deleteDocs = id => dispatch => {
    return api.user
        .deleteDocs(id)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                dispatch({
                    type: t.DELETE_DOCS,
                    payload: id,
                });

                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(() => message.success('Delete Docs!'));
};

// Archive

const getListArchive = idPage => dispatch => {
    return api.user.getListArchive(idPage).then(response => {
        const { data, error, status } = response;
        if (status === 200) {
            dispatch({
                type: t.GET_ARCHIVE,
                payload: data,
            });

            return response;
        } else {
            throw new Error(error);
        }
    });
};

// DOC_TYPE

const getListDocType = () => dispatch => {
    return api.user.getListDocType().then(response => {
        const { data, error, status } = response;
        if (status === 200) {
            dispatch({
                type: t.GET_DOC_TYPE,
                payload: data,
            });

            return response;
        } else {
            throw new Error(error);
        }
    });
};

const updateDocType = (id, data) => dispatch => {
    return api.user
        .updateDocType(id, data)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(item => {
            api.user
                .getListDocType()
                .then(response => {
                    const { data, error, status } = response;
                    if (status === 200) {
                        dispatch({
                            type: t.GET_DOC_TYPE,
                            payload: data,
                        });

                        return response;
                    } else {
                        throw new Error(error);
                    }
                })
                .finally(() => message.success('Update Docs Type!'));
        });
};

const createDocType = data => dispatch => {
    return api.user
        .createDocType(data)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(item => {
            api.user
                .getListDocType()
                .then(response => {
                    const { data, error, status } = response;
                    if (status === 200) {
                        dispatch({
                            type: t.GET_DOC_TYPE,
                            payload: data,
                        });

                        return response;
                    } else {
                        throw new Error(error);
                    }
                })
                .finally(() => message.success('Create Docs Type!'));
        });
};

const deleteDocType = id => dispatch => {
    return api.user
        .deleteDocType(id)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                dispatch({
                    type: t.DELETE_DOC_TYPE,
                    payload: id,
                });

                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(() => message.success('Delete Docs Type!'));
};

// TOPIC

const getListTopic = () => dispatch => {
    return api.user.getListTopic().then(response => {
        const { data, error, status } = response;
        if (status === 200) {
            dispatch({
                type: t.GET_TOPIC,
                payload: data,
            });

            return response;
        } else {
            throw new Error(error);
        }
    });
};

const updateTopic = (id, data) => dispatch => {
    return api.user
        .updateTopic(id, data)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(item => {
            api.user
                .getListTopic()
                .then(response => {
                    const { data, error, status } = response;
                    if (status === 200) {
                        dispatch({
                            type: t.GET_TOPIC,
                            payload: data,
                        });

                        return response;
                    } else {
                        throw new Error(error);
                    }
                })
                .finally(() => message.success('The topic has been updated successfully!'));
        });
};

const createTopic = data => dispatch => {
    return api.user
        .createTopic(data)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(item => {
            api.user
                .getListTopic()
                .then(response => {
                    const { data, error, status } = response;
                    if (status === 200) {
                        dispatch({
                            type: t.GET_TOPIC,
                            payload: data,
                        });

                        return response;
                    } else {
                        throw new Error(error);
                    }
                })
                .finally(() => message.success('The topic has been created successfully!'));
        });
};

const deleteTopic = id => dispatch => {
    return api.user
        .deleteTopic(id)
        .then(response => {
            const { error, status } = response;
            if (status === 201 || status === 200) {
                dispatch({
                    type: t.DELETE_TOPIC,
                    payload: id,
                });

                return response;
            } else {
                throw new Error(error);
            }
        })
        .finally(() => message.success('The topic has been deleted successfully!'));
};

// MAILING

const createMessage = (data, successCB) => () => {
    return api.user
        .createMessage(data)
        .then(() => {
            message.success('Notification has been created successfully!');
            successCB();
        })
        .catch(() => message.error('Something went wrong during create notification!'));
};

const updateMessage = (data, successCB) => () => {
    return api.user
        .updateMessage(data)
        .then(() => {
            message.success('Saved!');
            successCB?.();
        })
        .catch(() => message.error('Something went wrong during update notification!'));
};

export {
    userLogin,
    userLogout,
    getUsers,
    getUsersBySearch,
    getMe,
    getListDocsMe,
    getListDocsFavoritesMe,
    addToFavorites,
    deleteToFavorites,
    updateUser,
    createUsers,
    deleteUser,
    getListRole,
    updateRole,
    createRole,
    deleteRole,
    getListDocs,
    updateDocs,
    createDocs,
    deleteDocs,
    getListArchive,
    getListDocType,
    updateDocType,
    createDocType,
    deleteDocType,
    getListTopic,
    updateTopic,
    createTopic,
    deleteTopic,
    createMessage,
    updateMessage,
};
