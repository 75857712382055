import { combineReducers } from 'redux';
import * as t from '../types';
import { messagesReducer } from './messages/reducer';

import user from './user';

const applicationReducer = combineReducers({
    user,
    messagesReducer,
});

const rootReducer = (state, action) => {
    if (action.type === t.USER_LOGOUT) {
        state = undefined;
    }
    return applicationReducer(state, action);
};

export default rootReducer;
