import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import './footer.scss';

const Footer = props => {
    return (
        <div className="c-footer">
            <div className="c-footer__main">
                <div>
                    <h4 className="c-footer__title">Pages Link</h4>
                    <nav>
                        <a href="http://cius.org/privacy-policy/">Privacy Policy</a>
                    </nav>
                </div>
                <div>
                    <h4 className="c-footer__title">Contact</h4>
                    <div className="c-footer__contact">
                        <a href="https://www.google.com/maps/place/Avenue+des+Nerviens+9,+1040+Etterbeek,+Belgium/@50.8392639,4.3843147,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3c4a3a40f8f7b:0x44bc7f722b3b26ea!8m2!3d50.8392639!4d4.3865034">
                            <span className="c-footer__icon">
                                <FontAwesomeIcon icon={faMapMarker} />
                            </span>
                            <span>
                                Avenue des Nerviens 9-31<br></br>B - 1040 Brussels<br></br>Belgium
                            </span>
                        </a>
                        <a href="tel:+3225112781">
                            <span className="c-footer__icon">
                                <FontAwesomeIcon icon={faPhone} />
                            </span>
                            +32 2 511 27 81
                        </a>
                        <a href="mailto:cius@cius.org">
                            <span className="c-footer__icon">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                            cius@cius.org
                        </a>
                        <a
                            href="https://twitter.com/eusugarusers"
                            className="twitter"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    </div>
                </div>
                <div>
                    <h4 className="c-footer__title">Disclaimer</h4>
                    <p>
                        All content provided on this website is for informational purposes only.
                        CIUS makes no representations as to the accuracy or completeness of any
                        information on this site or found by following any link on this site.
                    </p>
                </div>
            </div>
            <div className="c-footer__copyright">
                <div>Copyright © 2021 CIUS | All rights reserved </div>
                <a href="https://www.white-rabb.it/">
                    <img src="/images/signature.png" alt="white-rabbit" />
                </a>
            </div>
        </div>
    );
};

Footer.propTypes = {};

export default Footer;
