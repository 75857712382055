const TOKEN_KEY = 'accessToken';

export const isLogin = () => {
    return !!window.localStorage.getItem(TOKEN_KEY);
};

export const getToken = () => {
    return window.localStorage.getItem(TOKEN_KEY);
};

export const setToken = token => {
    window.localStorage.setItem(TOKEN_KEY, token);
};

export const clearToken = () => {
    window.localStorage.removeItem('accessToken');
};

export const setLocalStorage = (name, value) => {
    window.localStorage.setItem(name, JSON.stringify(value));
};

export const getLocalStorage = name => {
    return JSON.parse(window.localStorage.getItem(name));
};

export default {
    isLogin,
    getToken,
    setToken,
    clearToken,
    setLocalStorage,
    getLocalStorage,
};
