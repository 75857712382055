export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'
export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'
export const CLEAR_ERROR_STATE = 'CLEAR_ERROR_STATE'
export const USER_LOGOUT = 'USER_LOGOUT'

export const GET_ME = 'GET_ME'
export const GET_MY_DOCS = 'GET_MY_DOCS'
export const GET_MY_FAVORITES_DOCS = 'GET_MY_FAVORITES_DOCS'

export const GET_USERS = 'GET_USERS'
export const GET_USERS_BY_SEARCH = 'GET_USERS_BY_SEARCH'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'

export const GET_ROLES = 'GET_ROLES'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'


export const GET_DOCS = 'GET_DOCS'
export const UPDATE_DOCS = 'UPDATE_DOCS'
export const DELETE_DOCS = 'DELETE_DOCS'
export const GET_ARCHIVE = 'GET_ARCHIVE'

export const GET_DOC_TYPE = 'GET_DOC_TYPE'
export const UPDATE_DOC_TYPE = 'UPDATE_DOC_TYPE'
export const DELETE_DOC_TYPE = 'DELETE_DOC_TYPE'

export const GET_TOPIC = 'GET_TOPIC'
export const UPDATE_TOPIC = 'UPDATE_TOPIC'
export const DELETE_TOPIC = 'DELETE_TOPIC'

export const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES'
export const DELETE_TO_FAVORITES = 'DELETE_TO_FAVORITES'