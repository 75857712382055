import React, { useEffect, useState } from 'react'

import { NavLink } from 'react-router-dom'

import {
  Upload,
  Button,
  Table,
  Modal,
  Form,
  Input,
  Select,
  Tabs
} from 'antd'

import { UploadOutlined } from '@ant-design/icons'

import './Upload.scss'

const UploadFile = ({
  user: {
    docs,
    docType,
    roles,
    topic
  },
  getListDocs,
  createDocs,
  getListDocType,
  deleteDocs,
  updateDocs,
  getListRole,
  updateDocType,
  createDocType,
  deleteDocType,
  getListTopic,
  updateTopic,
  createTopic,
  deleteTopic
}) => {
  const [fileList, setFileList] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [infoFile, setInfoFile] = useState({})
  const [viewModal, setViewModal] = useState('')
  const [isModalTypeVisible, setIsModalTypeVisible] = useState(false)
  const [isModalTopicVisible, setIsModalTopicVisible] = useState(false)
  const [filterDocs, setFilterDocs] = useState([])
  const [filterTopic, setFilterTopic] = useState([])
  const [filterType, setFilterType] = useState([])

  const [form] = Form.useForm()

  useEffect(() => {
    getListDocs(1)
    getListDocType()
    getListRole()
    getListTopic()
  }, [])

  useEffect(() => {
    setFilterDocs(docs?.data)
  }, [docs])

  useEffect(() => {
    setFilterTopic(topic && topic)
  }, [topic])

  useEffect(() => {
    setFilterType(docType && docType)
  }, [docType])

  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: file => {
      setFileList([...fileList, file])
      return false
    },
    fileList
  }

  const columns = [
    {
      title: 'Format',
      dataIndex: 'file_type',
      key: 'file_type',
      responsive: ['md'],
    },
    {
      title: 'Name file',
      dataIndex: 'file_name',
      key: 'file_name'
    },
    {
      title: 'Topic',
      dataIndex: 'topics',
      key: 'topics',
      render: (i) => i?.map((item, key) => <div key={key}>{item.name}</div>)
    },
    {
      title: 'Type',
      dataIndex: 'doc_type_id',
      key: 'doc_type_id',
      render: (i) => docType?.map((item, key) => item.id === i && <div key={key}>{item.name}</div>)
    },
    {
      title: '',
      render: (i) => <>
        <div>
          <Button className='btn btn-small' onClick={() => showModal('edit', i)}>Edit</Button>
          <Button className='btn btn-small' onClick={() => onDeleteFile(i)}>Archive</Button>
        </div>
      </>
    }
  ]

  const onFinishForm = (values) => {
    if (viewModal === 'create') {
      const formData = new FormData()

      values.topics?.map((item, key) =>
        formData.append(`topics[${++key}]`, item)
      )
      formData.append('doc_type_id', values.doc_type_id)
      formData.append('file', values.file.file)
      formData.append('file_name', values.file_name)
      values.roles?.map((item, key) =>
        formData.append(`roles[${++key}]`, item)
      )

      createDocs(formData)
    } else {
      updateDocs(infoFile.id, {
        file_name: values.file_name,
        doc_type_id: values.doc_type_id,
        topics: values.topics,
        roles: values.roles
      })
    }

    setFileList([])
    form.resetFields()
    setIsModalVisible(false)
  }

  const onFinishFormType = (values) => {
    if (viewModal === 'create') {
      createDocType(values)
    } else {
      updateDocType(infoFile.id, values)
    }

    setFileList([])
    setIsModalTypeVisible(false)
    form.resetFields()
  }

  const onFinishFormTopic = (values) => {
    if (viewModal === 'create') {
      createTopic(values)
    } else {
      updateTopic(infoFile.id, values)
    }

    setFileList([])
    setIsModalTopicVisible(false)
    form.resetFields()
  }


  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsModalTypeVisible(false)
    setIsModalTopicVisible(false)
    form.resetFields()
    setFileList([])
  }

  const showModal = (type, i) => {
    setIsModalVisible(true)
    setViewModal(type)

    if (type === 'edit') {
      setInfoFile(i)
      form.setFieldsValue({ ...i, roles: i.roles.map(item => item.id), topics: i.topics.map(item => item.id) })
    }
  }

  const showModalType = (type, i) => {
    setIsModalTypeVisible(true)
    setViewModal(type)
    if (type === 'edit') {
      setInfoFile(i)
      form.setFieldsValue(i)
    }
  }

  const showModalTopic = (type, i) => {
    setIsModalTopicVisible(true)
    setViewModal(type)
    if (type === 'edit') {
      setInfoFile(i)
      form.setFieldsValue(i)
    }
  }


  const onDeleteFile = (item) => {
    updateDocs(item.id, { roles: [] })
  }

  const onDeleteTopic = (item) => {
    deleteTopic(item.id)
  }

  const onDeleteType = (item) => {
    deleteDocType(item.id)
  }

  const columnsType = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      align: 'right',
      key: 'button',
      render: (i) => <>
        <div>
          <Button className='btn btn-small' onClick={() => showModalType('edit', i)}>Edit</Button>
          <Button className='btn btn-small' onClick={() => onDeleteType(i)}>Delete</Button>
        </div>
      </>
    }
  ]

  const columnsTopic = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      align: 'right',
      key: 'button',
      render: (i) => <>
        <div>
          <Button className='btn btn-small' onClick={() => showModalTopic('edit', i)}>Edit</Button>
          <Button className='btn btn-small' onClick={() => onDeleteTopic(i)}>Delete</Button>
        </div>
      </>
    }
  ]

  const searchDocs = value => {
    const filterTable = docs?.data?.filter(o =>
      String(o.file_name)
        .toLowerCase()
        .includes(value.toLowerCase())
    )

    setFilterDocs(filterTable)
  }

  const searchTopic = value => {
    const filterTable = topic?.filter(o =>
      String(o.name)
        .toLowerCase()
        .includes(value.toLowerCase())
    )

    setFilterTopic(filterTable)
  }

  const searchType = value => {
    const filterTable = docType?.filter(o =>
      String(o.name)
        .toLowerCase()
        .includes(value.toLowerCase())
    )

    setFilterType(filterTable)
  }

  const onChangePagination = value => {
    getListDocs(value)
  }

  return <div className='upload'>
    <Button type="primary" className="btn btn-solid btn-small btn-left" >
      <NavLink exact to="/">Back</NavLink>
    </Button>
    <Tabs className='upload__tabs'>
      <Tabs.TabPane tab="Docs" key="1">

        <Button className='btn btn-small' onClick={() => showModal('create')}>Upload file</Button>
        <Input.Search
          placeholder="ALL"
          enterButton
          onSearch={searchDocs}
          className="search"
        />
        <Table
          className='upload__table'
          columns={columns}
          dataSource={filterDocs}
          pagination={{
            PageSize: 15,
            total: filterDocs?.length,
            onChange: onChangePagination
          }}
        />

        <Modal
          title={viewModal === 'create' ? 'Upload file' : 'Edit file'}
          className='modal__form'
          centered
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            layout={'vertical'}
            form={form}
            onFinish={onFinishForm}
          >
            {viewModal === 'create' && <Form.Item
              name='file'
              rules={[{ required: true, message: 'Please select your File!' }]}
            >
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            </Form.Item>}
            {viewModal === 'edit' && <Form.Item
              label='Name'
              name='file_name'
              rules={[{ required: true, message: 'Please input your Name!' }]}
            >
              <Input />
            </Form.Item>}
            <Form.Item
              label='Role'
              name='roles'
              rules={[{ required: true, message: 'Please select your Role!' }]}

            >
              <Select
                mode="multiple"
              >
                {roles && roles.data.map((item, key) => <Select.Option key={key} value={item.id}>{item.name}</Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              label='Topic'
              name='topics'
              rules={[{ required: true, message: 'Please select your Topic!' }]}
            >
              <Select
                mode="multiple"
              >
                {topic && topic.map((item, key) => <Select.Option key={key} value={item.id}>{item.name}</Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              label='Type'
              name='doc_type_id'
              rules={[{ required: true, message: 'Please select your Type!' }]}
            >
              <Select>
                {docType && docType.map((item, key) => <Select.Option key={key} value={item.id}>{item.name}</Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType='submit'
                block
                type='primary'
                className='btn btn-small'
              >
                Save
            </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Topic" key="2">

        <Button className='btn btn-small' onClick={() => showModalTopic('create')}>Create</Button>

        <Input.Search
          placeholder="ALL"
          enterButton
          onSearch={searchTopic}
          className="search"
        />

        <Table className='upload__table' columns={columnsTopic} dataSource={filterTopic} />

        <Modal
          title={viewModal === 'create' ? 'Create topic' : 'Edit topic'}
          className='modal__form'
          centered
          visible={isModalTopicVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            layout={'vertical'}
            form={form}
            onFinish={onFinishFormTopic}
          >
            <Form.Item
              label='Topic'
              name='name'
              rules={[{ required: true, message: 'Please input topic!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType='submit'
                block
                type='primary'
                className='btn btn-small'
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>

      </Tabs.TabPane>
      <Tabs.TabPane tab="Type" key="3">
        <Button className='btn btn-small' onClick={() => showModalType('create')}>Create</Button>
        <Input.Search
          placeholder="ALL"
          enterButton
          onSearch={searchType}
          className="search"
        />
        <Table className='upload__table' columns={columnsType} dataSource={filterType} />

        <Modal
          title={viewModal === 'create' ? 'Create type' : 'Edit type'}
          className='modal__form'
          centered
          visible={isModalTypeVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            layout={'vertical'}
            form={form}
            onFinish={onFinishFormType}
          >
            <Form.Item
              label='Type'
              name='name'
              rules={[{ required: true, message: 'Please input type!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType='submit'
                block
                type='primary'
                className='btn btn-small'
              >
                Save
            </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Tabs.TabPane>
    </Tabs>
  </div>
}

export default UploadFile
