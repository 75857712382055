// import * as t from '../types'
// import { clearToken } from '../../utils'

const logout = store => next => action => {
  // if (action.type === t.USER_LOGOUT) {
  //   clearToken()
  //   window.location.href = '/login'
  // }
  return next(action)
}

export default logout
