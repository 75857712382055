import React from 'react';
import { Tabs } from 'antd';
import MailList from '../MailList/MailList';
import './UserPage.scss';

const UserPage = () => (
    <div className="users userPage">
        <Tabs defaultActiveKey="3" className="users__tabs" >
            <Tabs.TabPane tab="Notifications" key="3">
                <div className="userNotificationsWrap">
                    <MailList />
                </div>
            </Tabs.TabPane>
        </Tabs>
    </div>
);
export default UserPage;
