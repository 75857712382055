import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

import { isLogin } from '../../../utils'

const PrivateRoute = ({ user, component: Component, getMe, ...rest }) => {
  useEffect(() => {
    Object.keys(user.infoMe).length === 0 && getMe()
  }, [])

  return (
    <Route
      {...rest}
      render={props =>
        isLogin()
          ? (
            <div>
              <Component {...props} />
            </div>
            )
          : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object
}

export default PrivateRoute
