import * as t from '../types'
import { status } from '../../utils'

const initialState = {
  status: status.DEFAULT,
  data: {},
  error: null,
  users: [],
  docs: [],
  docType: [],
  archive: [],
  topic: [],
  myDocs: [],
  infoMe: {},
  idFavorites: [],
  myFavoritesDocs: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.GET_USER_REQUEST:
      return {
        ...initialState,
        status: status.FETCHING
      }
    case t.GET_USER_SUCCESS:
      return {
        ...state,
        status: status.SUCCESS,
        data: action.payload
      }
    case t.GET_USER_FAILURE:
      return {
        ...initialState,
        status: status.FAILURE,
        error: action.payload
      }
    case t.USER_LOGIN_REQUEST:
      return {
        ...initialState,
        status: status.FETCHING
      }
    case t.USER_LOGIN_SUCCESS:
      return {
        ...state,
        status: status.SUCCESS,
        data: action.payload
      }
    case t.USER_LOGIN_FAILURE:
      return {
        ...initialState,
        status: status.FAILURE,
        error: action.payload
      }

    // USER

    case t.GET_USERS:
      return {
        ...state,
        users: action.payload
      }
    case t.GET_USERS_BY_SEARCH:
      return {
        ...state,
        users: action.payload
      }
    case t.DELETE_USER:
      return {
        ...state,
        users: { state, data: state.users.data.filter(item => item.id !== action.payload) }
      }

    // ME

    case t.GET_ME:
      return {
        ...state,
        infoMe: action.payload
      }
    case t.GET_MY_DOCS:
      return {
        ...state,
        myDocs: action.payload
      }
    case t.GET_MY_FAVORITES_DOCS:
      let helper = []
      for (let i = 0; i < action.payload.data.length; i++) {
        helper.push(action.payload.data[i].id)
      }
      return {
        ...state,
        myFavoritesDocs: action.payload,
        idFavorites: helper
      }

    // ROLE

    case t.GET_ROLES:
      return {
        ...state,
        roles: action.payload
      }

    case t.DELETE_ROLE:
      return {
        ...state,
        roles: { state, data: state.roles.data.filter(item => item.id !== action.payload) }
      }

    // DOCS

    case t.GET_DOCS:
      return {
        ...state,
        docs: { ...action.payload, data: action.payload.data }
      }

    case t.DELETE_DOCS:
      return {
        ...state,
        archive: { data: state.archive.data.filter(item => item.id !== action.payload) }
      }

    case t.UPDATE_DOCS:
      const help = [...state.docs.data.map(item => item.id !== action.payload.id ? item : action.payload)].filter(item => item.roles.length)
      return {
        ...state,
        docs: { data: help }
      }

    // Archive

    case t.GET_ARCHIVE:
      return {
        ...state,
        archive: action.payload
      }

    // DOC_TYPE

    case t.GET_DOC_TYPE:
      return {
        ...state,
        docType: action.payload
      }

    case t.DELETE_DOC_TYPE:
      return {
        ...state,
        docType: state.docType.filter(item => item.id !== action.payload)
      }

    // TOPIC

    case t.GET_TOPIC:
      return {
        ...state,
        topic: action.payload
      }

    case t.DELETE_TOPIC:
      return {
        ...state,
        topic: state.topic?.filter(item => item.id !== action.payload)
      }

    case t.ADD_TO_FAVORITES:
      return {
        ...state,
        myFavoritesDocs: { ...state.myFavoritesDocs, data: [...state.myFavoritesDocs.data, ...state?.myDocs?.data?.filter(i => i.id === action?.payload?.doc_id)] },
        idFavorites: [...state.idFavorites, action.payload.doc_id]
      }

    case t.DELETE_TO_FAVORITES:
      return {
        ...state,
        myFavoritesDocs: { ...state.myFavoritesDocs, data: [...state.myFavoritesDocs.data.filter(i => i.id !== action.payload.doc_id)] },
        idFavorites: [...state.idFavorites.filter(i => i !== action.payload.doc_id)]
      }

    default: 
      return state
  }
}

export const isAdminSelector = state => !!state.user.infoMe?.roles?.find(role => role.id === 1)