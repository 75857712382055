import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types'
import {
    Form,
    Input,
    Button
  } from 'antd'
import './searchform.scss';

const SearchForm = props => {

  return (
    <Form         
        name="advanced_search"
        className="search-form">
            <div className="search-form__icon">
                <FontAwesomeIcon icon={faSearch} />
            </div>
            <Input placeholder='Search...' className="search-form__input" />
    </Form>
  )
}

SearchForm.propTypes = {
}

export default SearchForm