import PrivateRoute from './PrivateRoute'

import { connect } from 'react-redux'
import actions from '../../../store/actions'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getInfoUser: data => dispatch(actions.user.getInfoUser(data)),
  getMe: () => dispatch(actions.user.getMe())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
