import Users from './Users';

import { connect } from 'react-redux';
import actions from '../../store/actions';

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    getUsers: id => dispatch(actions.user.getUsers(id)),
    getUsersBySearch: (query, pageNumber) => dispatch(actions.user.getUsersBySearch(query, pageNumber)),
    getListRole: () => dispatch(actions.user.getListRole()),
    updateUser: (id, data, page) => dispatch(actions.user.updateUser(id, data, page)),
    createUsers: data => dispatch(actions.user.createUsers(data)),
    deleteUser: id => dispatch(actions.user.deleteUser(id)),
    updateRole: (id, data) => dispatch(actions.user.updateRole(id, data)),
    createRole: data => dispatch(actions.user.createRole(data)),
    deleteRole: id => dispatch(actions.user.deleteRole(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
