import { connect } from 'react-redux'
import actions from '../../store/actions'
import Login from './Login'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  userLogin: data => dispatch(actions.user.userLogin(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
