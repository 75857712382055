import Library from './Library'

import { connect } from 'react-redux'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  createDocs: (data) => dispatch(actions.user.createDocs(data)),
  getListDocType: () => dispatch(actions.user.getListDocType()),
  deleteDocs: (id) => dispatch(actions.user.deleteDocs(id)),
  updateDocs: (id, data) => dispatch(actions.user.updateDocs(id, data)),
  getListRole: () => dispatch(actions.user.getListRole()),
  updateDocType: (id, data) => dispatch(actions.user.updateDocType(id, data)),
  createDocType: (data) => dispatch(actions.user.createDocType(data)),
  getListArchive: (idPage) => dispatch(actions.user.getListArchive(idPage))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Library)