import React, {useEffect, useMemo, useState} from 'react'

import {Button, Form, Input, Modal, Popconfirm, Select, Table, Tabs} from 'antd'

import {NavLink} from 'react-router-dom'

import './Users.scss'

const { TabPane } = Tabs;

const Users = ({
  user: {
    users,
    roles
  },
  getUsers,
  getUsersBySearch,
  getListRole,
  updateUser,
  deleteUser,
  createUsers,
  updateRole,
  createRole,
  deleteRole
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [infoUser, setInfoUser] = useState()
  const [infoRole, setInfoRole] = useState()
  const [editOrCreate, setEditOrCreate] = useState()
  const [typeData, setTypeData] = useState()
  const [filterUsers, setFilterUsers] = useState([])
  const [filterRole, setFilterRole] = useState([])
  const [searchMode, setSearchMode] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const [form] = Form.useForm()
  useEffect(() => {
    getUsers()
    getListRole()
  }, [])

  useEffect(() => {
    setFilterUsers(users?.data)
  }, [users])

  useEffect(() => {
    setFilterRole(roles?.data)
  }, [roles])

  const showModal = (value = false, data, type) => {
    setTypeData(type)
    if (type === 'users') {
      setEditOrCreate(value)
      if (value) {
        form.setFieldsValue({
          ...data,
          roles: data.roles.map(item => item.id)
        })
        setInfoUser(data)
      }
    } else {
      setEditOrCreate(value)
      if (value) {
        form.setFieldsValue({
          name: data.name
        })
        setInfoRole(data)
      }
    }
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInfoUser(undefined)
    setInfoRole(undefined)
    form.resetFields()
  }

  const onFinishForm = (values) => {
    if (editOrCreate) {
      updateUser(infoUser.id, {
        firstname: infoUser.firstname !== values.firstname ? values.firstname : undefined,
        lastname: infoUser.lastname !== values.lastname ? values.lastname : undefined,
        email: infoUser.email !== values.email ? values.email : undefined,
        roles: infoUser.roles !== values.roles ? values.roles : undefined,
        password: infoUser.password !== values.password ? values.password : undefined
      }, users.current_page)
    } else {
      createUsers([values, users.current_page])
    }

    handleCancel()
  }

  function confirm(i, type) {
    if (type === 'user') {
      deleteUser(i.id)
    } else {
      deleteRole(i.id)
    }
  }

  function cancel(e) { }

  const columnRoles = [
    {
      title: 'Name Group',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '',
      key: 'edit',
      align: 'right',
      render: (i) => {
        return <>
          <div>
            <Button className='btn btn-small' onClick={() => showModal(true, i, 'roles')}>Edit</Button>
            <Popconfirm
              title="Are you sure to delete this role?"
              onConfirm={() => confirm(i, 'role')}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button className='btn btn-small'>Delete</Button>
            </Popconfirm>
          </div>
        </>
      }
    }
  ]

  const columnUsers = [
    {
      title: 'Username',
      dataIndex: 'firstname',
      key: 'firstname'
    },
    {
      title: 'Name',
      dataIndex: 'lastname',
      key: 'lastname'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Role',
      dataIndex: 'roles',
      key: 'roles',
      render: (i) => {
        return i.map((item, key) => <div key={key}>{item.name}</div>)
      }
    },
    {
      title: '',
      key: 'edit',
      render: (i) => {
        return <>
          <div>
            <Button className='btn btn-small' onClick={() => showModal(true, i, 'users')}>Edit</Button>
            <Popconfirm
              title="Are you sure to delete this user?"
              onConfirm={() => confirm(i, 'user')}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button className='btn btn-small'>Delete</Button>
            </Popconfirm>
          </div>
        </>
      }
    }
  ]

  const onFinishFormRoles = (values) => {
    if (editOrCreate) {
      updateRole(infoRole.id, values)
    } else {
      createRole(values)
    }

    handleCancel()
  }

  const searchUserName = (value) => {
    if (value === '') {
      if (searchMode) {
        setCurrentPage(1);
        getUsers(1)
      }
      setSearchMode(false);
      return;
    }

    setSearchMode(true);
    setCurrentPage(1);
    getUsersBySearch(value.toLowerCase(), 1)
  }

  const changeSearchValueHandler = (e) => {
    setSearchValue(e.target.value)
  }

  const searchRole = value => {
    const filterTable = roles?.data?.filter(o =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    )

    setFilterRole(filterTable)
  }

  const onChangePagination = value => {
    setCurrentPage(value);
    searchMode ? getUsersBySearch(searchValue, value) : getUsers(value);
  }

  const modalTitle = useMemo(() => {
    if (typeData === 'users') {
      return !!infoUser ? 'Edit user' : 'Create user'
    }

    return !!infoRole ? 'Edit role' : 'Create role';
  }, [typeData, !!infoUser, !!infoRole])

  return <div className='users'>
    <Button type="primary" className="btn btn-solid btn-small btn-left" >
      <NavLink exact to="/">Back</NavLink>
    </Button>
    <Tabs defaultActiveKey="1" className='users__tabs'>
      <TabPane tab="Users" key="1">
        <Button type='primary' className='btn' onClick={() => showModal(false, {}, 'users')}>Create User</Button>
        <Input.Search
          placeholder="ALL"
          allowClear
          enterButton
          onInput={changeSearchValueHandler}
          onSearch={searchUserName}
          className="search"
        />
        <Table
          className='users__table'
          columns={columnUsers}
          dataSource={filterUsers}
          pagination={{
            current: currentPage,
            pageSize: 15,
            onChange: onChangePagination,
            total: users.total
          }}
        />
      </TabPane>
      <TabPane tab="Roles" key="2">
        <Button type='primary' className='btn' onClick={() => showModal(false, {}, 'roles')}>Create Role</Button>
        <Input.Search
          placeholder="ALL"
          enterButton
          onSearch={searchRole}
          className="search"
        />
        <Table
          className='users__table'
          columns={columnRoles}
          dataSource={filterRole}
          pagination={{
            pageSize: 15,
            onChange: onChangePagination,
            total: users.total
          }}
        />
      </TabPane>
    </Tabs>
    <Modal
      title={modalTitle}
      className='modal__form'
      centered
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {typeData === 'users' ? <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinishForm}
      >
        <Form.Item
          label='Firstname'
          name='firstname'
          rules={[{ required: true, message: 'Please input your Firstname!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Lastname'
          name='lastname'
          rules={[{ required: true, message: 'Please input your Lastname!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Email'
          name='email'
          rules={[{ required: true, message: 'Please input your Email!', type: 'email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Role'
          name='roles'
          rules={[{ required: true, message: 'Please input your Role!' }]}
        >
          <Select
            mode="multiple"
          >
            {roles && roles.data.map((item, key) => <Select.Option key={key} value={item.id}>{item.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label='Password'
          name='password'
          rules={[{ required: !editOrCreate, message: 'Please input your Password!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType='submit'
            block
            type='primary'
            className='btn btn-small'
          >
            Save
          </Button>
        </Form.Item>
      </Form>
        : <Form
          layout={'vertical'}
          form={form}
          onFinish={onFinishFormRoles}
        >
          <Form.Item
            label='Role name'
            name='name'
            rules={[{ required: true, message: 'Please input your Role name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType='submit'
              block
              type='primary'
              className='btn btn-small'
            >
              Save
            </Button>
          </Form.Item>
        </Form>}
    </Modal>
  </div>
}

export default Users