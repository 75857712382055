import { Button, Input } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useDebouncedCallback } from 'use-debounce';
import { searchMessages } from '../../../../store/reducers/messages/actions';
import { isAdminSelector } from '../../../../store/reducers/user';
import styles from './NavBar.module.scss';

const searchDelay = 1000;

export const NavBar = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isAdmin = useSelector(isAdminSelector);
    const urlSearch = qs.parse(window.location.search);
    const initSearch = urlSearch.search;

    const handleSearch = ({ target: { value: searchValue } }) => {
        const pathname = isAdmin ? '/maillist' : '/';
        const search = qs.stringify({ ...urlSearch, search: searchValue })
        history.push({ pathname, search })
        dispatch(searchMessages(searchValue, isAdmin))
    }

    const debouncedSearch = useDebouncedCallback(handleSearch, searchDelay)

    return (
        <div className={styles.wrap}>
            {isAdmin && (
                <Button type="primary" className="btn btn-solid btn-small btn-left">
                    <Link exact to="/">Back</Link>
                </Button>
            )}
            <Input placeholder="Search" defaultValue={initSearch} className={styles.search} onChange={debouncedSearch} />
        </div>
    )
}