import UploadFile from './UploadFile'

import { connect } from 'react-redux'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getListDocs: (idPage) => dispatch(actions.user.getListDocs(idPage)),
  createDocs: (data) => dispatch(actions.user.createDocs(data)),
  getListDocType: () => dispatch(actions.user.getListDocType()),
  deleteDocs: (id) => dispatch(actions.user.deleteDocs(id)),
  updateDocs: (id, data) => dispatch(actions.user.updateDocs(id, data)),
  getListRole: () => dispatch(actions.user.getListRole()),
  updateDocType: (id, data) => dispatch(actions.user.updateDocType(id, data)),
  createDocType: (data) => dispatch(actions.user.createDocType(data)),
  deleteDocType: (id) => dispatch(actions.user.deleteDocType(id)),
  getListTopic: () => dispatch(actions.user.getListTopic()),
  updateTopic: (id, data) => dispatch(actions.user.updateTopic(id, data)),
  createTopic: (data) => dispatch(actions.user.createTopic(data)),
  deleteTopic: (id) => dispatch(actions.user.deleteTopic(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFile)
