import { clearToken } from '../../../../utils'

export default (instance) => {
  instance.interceptors.response.use(null, err => {
    if (err && err.response && err.response.status === 401) {
      clearToken()
    }
    return Promise.reject(err)
  })
}
