import * as types from './types';
import * as api from '../../../services/api/messages';

export const fetchMessagesSuccess = payload => ({
    type: types.FETCH_MESSAGES_LIST_SUCCESS,
    payload,
});

export const fetchMessagesFailure = () => ({
    type: types.FETCH_MESSAGES_LIST_FAILURE,
});

export const fetchMessages = (search = {}, isAdmin) => dispatch => {
    dispatch({
        type: types.FETCH_MESSAGES_LIST,
    });
    api.getMessages(search, isAdmin)
        .then(r => dispatch(fetchMessagesSuccess(r.data)))
        .catch(() => dispatch(fetchMessagesFailure()));
};

export const fetchSingleMessageSuccess = payload => ({
    type: types.FETCH_SINGLE_MESSAGE_SUCCESS,
    payload,
});

export const fetchSingleMessageFailure = () => ({
    type: types.FETCH_SINGLE_MESSAGE_FAILURE,
});

export const fetchSingleMessage = (id, isAdmin) => dispatch => {
    dispatch({
        type: types.FETCH_SINGLE_MESSAGE,
    });

    api.getMessage(id, isAdmin)
        .then(r => dispatch(fetchSingleMessageSuccess(r.data)))
        .catch(() => dispatch(fetchSingleMessageFailure()));
};

export const searchMessages = (value, isAdmin) => dispatch => {
    dispatch({
        type: types.FETCH_MESSAGES_LIST,
    });

    api.searchMessages(value, isAdmin)
        .then(r => dispatch(fetchMessagesSuccess(r.data)))
        .catch(() => dispatch(fetchMessagesFailure()));
}

export const clearSingleMessage = () => ({
    type: types.CLEAR_SINGLE_MESSAGE,
})