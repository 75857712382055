import UserPage from './UserPage'

import { connect } from 'react-redux'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getListDocType: () => dispatch(actions.user.getListDocType()),
  getListDocsMe: (page) => dispatch(actions.user.getListDocsMe(page)),
  getListDocsFavoritesMe: (page) => dispatch(actions.user.getListDocsFavoritesMe(page)),
  addToFavorites: (data) => dispatch(actions.user.addToFavorites(data)),
  deleteToFavorites: (data) => dispatch(actions.user.deleteToFavorites(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPage)