import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import styles from './Documents.module.scss';

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
    },
    {
        title: '',
        dataIndex: 'url',
        key: 'url',
        render: url => (
            <a
                href={`${process.env.REACT_APP_BASE_URL}${url}`}
                target="__blank"
                rel="noopener noreferrer nofollow"
            >
                Download
            </a>
        ),
    },
];

export const Documents = ({ docs }) => {
    const dataSource = docs.map(doc => ({
        key: doc.id,
        title: doc.file_name,
        created_at: moment(doc.created_at).format('DD.MMMM.YYYY'),
        url: doc.file_url,
    }));

    return (
        <div className={styles.wrap}>
            <div className={styles.title}>Documents</div>
            <Table dataSource={dataSource} columns={columns} />
        </div>
    );
};
