import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import {
  Button,
  Table,
  Modal,
  Form,
  Input,
  Select
} from 'antd'

import './Library.scss'

const Library = ({
  user: {
    docType,
    roles,
    topic,
    archive
  },
  getListDocType,
  deleteDocs,
  updateDocs,
  getListRole,
  getListArchive
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [infoFile, setInfoFile] = useState({})
  const [filterDocsArhivet, setFilterDocsArhivet] = useState([])

  const [form] = Form.useForm()

  useEffect(() => {
    getListDocType()
    getListRole()
    getListArchive(1)
  }, [])

  useEffect(() => {
    setFilterDocsArhivet(archive?.data)
  }, [archive])

  const columns = [
    {
      title: 'Format',
      dataIndex: 'file_type',
      key: 'file_type',
      responsive: ['md'],
    },
    {
      title: 'Name file',
      dataIndex: 'file_name',
      key: 'file_name'
    },
    {
      title: 'Topic',
      dataIndex: 'topics',
      key: 'topics',
      render: (i) => i?.map((item, key) => <div key={key}>{item.name}</div>)
    },
    {
      title: 'Type',
      dataIndex: 'doc_type_id',
      key: 'doc_type_id',
      render: (i) => docType?.map((item, key) => item.id === i && <div key={key}>{item.name}</div>)
    },
    {
      title: '',
      render: (i, b) => <>
        <div>
          <Button className='btn btn-small' onClick={() => showModal('edit', i)}>Edit</Button>
          <Button className='btn btn-small' onClick={() => onDeleteFile(i)}>Delete</Button>
        </div>
      </>
    }
  ]

  const onFinishForm = (values) => {
    updateDocs(infoFile.id, {
      file_name: values.file_name,
      doc_type_id: values.doc_type_id,
      topics: values.topics,
      roles: values.roles
    })

    setIsModalVisible(false)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    form.resetFields()
  }

  const showModal = (type, i) => {
    setIsModalVisible(true)
    if (type === 'edit') {
      setInfoFile(i)
      form.setFieldsValue({ ...i, roles: i.roles.filter(item => item.id), topics: i.topics.map(item => item.id) })
    }
  }

  const onDeleteFile = (item) => {
    deleteDocs(item.id)
  }

  const searchDocsArhivet = value => {
    const filterTable = archive.data?.filter(o =>
        String(o.file_name)
          .toLowerCase()
          .includes(value.toLowerCase())
    )

    setFilterDocsArhivet(filterTable)
  }

  const onChangePagination = value => {
    getListArchive(value)
  }

  return <div className='library'>
    <Button type="primary" className="btn btn-solid btn-small btn-left">
      <NavLink exact to="/">Back</NavLink>
    </Button>

    <Input.Search
      placeholder="ALL"
      enterButton
      onSearch={searchDocsArhivet}
      className="search"
    />

    <Table
      className='library__table'
      columns={columns}
      dataSource={filterDocsArhivet}
      pagination={{
        PageSize: 15,
        total: filterDocsArhivet?.length,
        onChange: onChangePagination
      }}
    />

    <Modal
      title='Edit file'
      className='modal__form'
      centered
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinishForm}
      >
        <Form.Item
          label='Role'
          name='roles'
          rules={[{ required: true, message: 'Please select your Role!' }]}
        >
          <Select
            mode="multiple"
          >
            {roles && roles.data.map((item, key) => <Select.Option key={key} value={item.id}>{item.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label='Topic'
          name='topics'
          rules={[{ required: true, message: 'Please select your Topic!' }]}
        >
          <Select
            mode="multiple"
          >
            {topic && topic.map((item, key) => <Select.Option key={key} value={item.id}>{item.name}</Select.Option>)}

          </Select>
        </Form.Item>
        <Form.Item
          label='Type'
          name='doc_type_id'
          rules={[{ required: true, message: 'Please select your Type!' }]}
        >
          <Select>
            {docType && docType.map((item, key) => <Select.Option key={key} value={item.id}>{item.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType='submit'
            block
            type='primary'
            className='btn btn-small'
          >
            Save
            </Button>
        </Form.Item>
      </Form>
    </Modal>
  </div >
}

export default Library
