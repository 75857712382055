import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import './header.scss';
import { Organism } from '../../../components';

const Header = props => {
    const [visibleClass, setVisibleClass] = useState(false);
    const setClass = () => {
        setVisibleClass(!visibleClass);
    };
    return (
        <div className="c-header">
            <div className="c-header__logo">
                <a href="http://cius.org/" title="Go to the Homepage">
                    <img src="/images/logo.png" alt="Logotype of CIUS" />
                </a>
            </div>
            <button className="c-header__button" onClick={setClass}>
                <FontAwesomeIcon icon={faBars} />
            </button>
            <Organism.Sidebar funk={() => setClass()} vis={visibleClass} />
        </div>
    );
};

Header.propTypes = {};

export default Header;
