import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useUnmount } from 'react-use';
import { clearSingleMessage, fetchSingleMessage } from '../../store/reducers/messages/actions';
import { singleMessageSelector } from '../../store/reducers/messages/selectors';
import { isAdminSelector } from '../../store/reducers/user';
import { Documents } from './components/Documents/Documents';
import { MainInfo } from './components/MainInfo/MainInfo';
import { NavBar } from './components/NavBar/NavBar';

import styles from './MailItemView.module.scss';

export const MailItemView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const message = useSelector(singleMessageSelector);
    const isAdmin = useSelector(isAdminSelector);
    
    useEffect(() => {
        dispatch(fetchSingleMessage(id, isAdmin));
    }, []);

    useUnmount(() => dispatch(clearSingleMessage()))

    if (!message) {
        return null;
    }

    return (
        <div className={styles.wrap}>
            <NavBar isAdmin={isAdmin} id={id}/>
            <MainInfo message={message} />
            {message.docs.length > 0 && <Documents docs={message.docs} />}
        </div>
    );
};
