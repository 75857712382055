import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <div>
          <Component {...props} />
        </div>
      )}
    />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.elementType
}

export default PublicRoute
