import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { Pagination } from '../../components/Pagination/Pagination';
import { searchMessages } from '../../store/reducers/messages/actions';
import {
    messagesListPaginationSelector,
    messagesListSelector,
} from '../../store/reducers/messages/selectors';
import { Item } from './components/Item/Item';

import styles from './MailList.module.scss';
import { NavBar } from './components/NavBar/NavBar';
import { isAdminSelector } from '../../store/reducers/user';
import { useHistory } from 'react-router';

const MailList = () => {
    const dispatch = useDispatch();
    const list = useSelector(messagesListSelector);
    const isAdmin = useSelector(isAdminSelector);
    const history = useHistory();
    const { current_page, last_page } = useSelector(messagesListPaginationSelector);
    const urlSearch = qs.parse(window.location.search);
    const initPage = urlSearch.page || 1;
    const isEmpty = list.length === 0;

    useEffect(() => {
        dispatch(searchMessages({ ...urlSearch, page: initPage }, isAdmin));
    }, []);

    const handlePageChange = ({ selected }) => {
        const nextPage = selected + 1;
        const pathname = isAdmin ? '/maillist' : '/';
        const params = { ...urlSearch, page: nextPage };
        const search = qs.stringify(params);

        history.push({ pathname, search: search });
        dispatch(searchMessages(params, isAdmin));
    };

    return (
        <div className={styles.wrap}>
            <NavBar />
            <div className={styles.list}>
                {isEmpty
                    ? 'No data'
                    : list.map(message => <Item key={message.id} message={message} />)}
            </div>
            <Pagination
                onPageChange={handlePageChange}
                forcePage={current_page - 1}
                pageCount={last_page}
            />
        </div>
    );
};

export default MailList;
