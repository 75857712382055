import React, { useEffect, useState } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {  Button, Form, Input, Select, message as notify } from "antd";
import "./Mailing.scss";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { FileUpload } from "./components/FileUpload/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { singleMessageSelector } from "../../store/reducers/messages/selectors";
import { isAdminSelector } from "../../store/reducers/user";
import { clearSingleMessage, fetchSingleMessage } from "../../store/reducers/messages/actions";
import { createMessageFiles, deleteMessageFiles } from "../../services/api/messages";
import { createMessage, updateMessage } from "../../services/api/user";
import htmlToDraft from 'html-to-draftjs'
import draftToHTML from 'draftjs-to-html';
import {useUnmount} from 'react-use';

const Mailing = ({
  user: { roles},
  getListRole,
}) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const message = useSelector(singleMessageSelector);
  const isAdmin = useSelector(isAdminSelector);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
      if(id) {
        dispatch(fetchSingleMessage(id, isAdmin));
      }
  }, [id]);

  useUnmount(() => dispatch(clearSingleMessage()))

  useEffect(() => {
    if(!!message) {
      form.setFieldsValue({
        subject: message.subject,
        body: message.body,
        roles: message.roles.map(role => role.id),
      })

      const contentBlock = htmlToDraft(message.body);
      
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
      }

      setFiles(message.docs.map(doc => ({...doc, name: doc.file_name})))
    }
  }, [!!message])

  const handleEditorChange = (state) => setEditorState(state);

  useEffect(() => {
    getListRole();
  }, []);

  const onFinishForm = async (values) => {
    const formData = new FormData();
    const ctr = convertToRaw(editorState.getCurrentContent());
    const converted = draftToHTML(ctr);

    formData.append("body", converted);
    formData.append("subject", values.subject);

    values.roles.map((item, key) => formData.append(`roles[${key}]`, item));
    
    if(!!id) {
      if(removedFiles.length > 0) {
        await deleteMessageFiles(id, removedFiles)
      }

      if(files.length > 0) {
        const newFiles = await files.filter(f => !f.id)

        if(newFiles.length > 0) {
          newFiles.forEach((file, key) => {
            formData.append(`files[${key}]`, file);
          });

          await createMessageFiles(id, formData)
        }
      }
      
      updateMessage(formData, id).then(() => {
        notify.success('Notification has been updated successfully!')
        form.resetFields()
        history.push(`/maillist/${id}`, {prev: history.location});
      });
    }
    else {
      files.forEach((file, key) => {
        formData.append(`files[${key}]`, file);
      });
      
      createMessage(formData).then(() => {
        notify.success('Notification has been created successfully!')
        form.resetFields()
        history.push('/')
      }) 
    }
  };

  return (
    <div className="mailing">
      <Button type="primary" className="btn btn-solid btn-small btn-left">
        <NavLink to={'/'}>
          Back
        </NavLink>
      </Button>
      <div className="mailing__content">
        <Form layout={"vertical"} form={form} onFinish={onFinishForm}>
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: "Please input your subject!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Roles"
            name="roles"
            rules={[{ required: true, message: "Please select roles!" }]}
          >
            <Select mode="multiple" filterOption optionFilterProp="children">
              {roles?.data.map((item, key) => (
                  <Select.Option  key={key} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Message"
            name="body"
            rules={[{ required: true, message: "Please input your message!" }]}
          >
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={{
                options: [
                  'inline', 
                  'fontSize', 
                  'fontFamily', 
                  'list', 
                  'textAlign', 
                  'colorPicker', 
                ],
                inline: {
                  visible: true,
                  inDropdown: false,
                },
            }}
            />
          </Form.Item>
          <FileUpload files={files} setFiles={setFiles} setRemovedFiles={setRemovedFiles}/>
          <Form.Item>
            <Button htmlType="submit" className="btn">
              {id ? 'Save' : 'Send'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Mailing;
